<template>
  <div>
    <v-row class="app-invoice-preview">
      <v-col
        cols="12"
      >
        <v-card
          v-if="paymentData"
          class="mb-10"
        >
          <v-fab-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  dark
                  absolute
                  small
                  top
                  right
                  fab
                  class="mb-4 me-3"
                  v-on="on"
                  @click="goBack"
                >
                  <v-icon>
                    {{ icons.mdiTableArrowLeft }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t("tooltip.back_table") }}</span>
            </v-tooltip>
          </v-fab-transition>

          <v-card-text class="py-7 px-8">
            <v-row class="d-flex justify-space-between">
              <v-col
                cols="12"
                md="3"
              >
                <div class="d-flex align-center">
                  <v-avatar
                    :color="computedFacility.logo ? '' : 'primary'"
                    :class="computedFacility.logo ? '' : 'v-avatar-light-bg primary--text'"
                    size="64"
                  >
                    <v-img
                      v-if="computedFacility.logo"
                      :src="computedFacility.logo"
                    ></v-img>
                    <span
                      v-else
                      class="font-weight-regular"
                    >{{ avatarText(computedFacility.name) }}</span>
                  </v-avatar>

                  <h2 class="font-weight-medium ml-2">
                    {{ computedFacility.name }}
                  </h2>
                </div>
              </v-col>

              <v-col
                class="d-flex align-center"
                cols="12"
                md="3"
              >
                <div class="d-flex align-center">
                  <h2 class="font-weight-medium">
                    {{ t('transactions.payment_receipt') }}
                  </h2>
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-space-between">
              <v-col
                class="d-flex justify-space-between"
                cols="12"
                md="3"
              >
                <div>
                  <table>
                    <tr>
                      <td>
                        {{ computedFacility.name }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {{ computedFacility.phone }}
                      </td>
                    </tr>
                  </table>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <div>
                  <table>
                    <tr>
                      <td class="pe-6">
                        {{ t('transactions.date') }}:
                      </td>
                      <td>
                        {{ paymentData.payment_date }}
                      </td>
                    </tr>

                    <tr>
                      <td class="pe-6">
                        ID:
                      </td>
                      <td>
                        #{{ paymentData.id }}
                      </td>
                    </tr>
                    <tr>
                      <td class="pe-6">
                        {{ t('status.status') }}:
                      </td>
                      <td>
                        {{ paymentData.status_str }}
                      </td>
                    </tr>
                  </table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="py-7 px-8">
            <div class="d-flex justify-end mb-2">
              <v-btn
                v-if="hasPermission(98)"
                class="left-align"
                color="secondary"
                x-small
                @click="generateReport()"
              >
                <v-icon
                  class="me-2"
                  left
                >
                  {{ icons.mdiPrinter }}
                </v-icon>
                <span>{{ t('tooltip.print') }}</span>
              </v-btn>
            </div>

            <v-simple-table
              v-if="paymentData.status !== 'D'"
              class="purchased-items-table-bordered"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>
                      {{ t('transactions.id').toUpperCase() }}
                    </th>
                    <th>
                      {{ t('transactions.transaction_date').toUpperCase() }}
                    </th>
                    <th v-if="hasTransactionVideo">
                      {{ t('transactions.video_info').toUpperCase() }}
                    </th>
                    <th v-if="hasTransactionVideo">
                      {{ t('transactions.field').toUpperCase() }}
                    </th>
                    <th v-if="hasTransactionVideo">
                      {{ t('transactions.start_time').toUpperCase() }}
                    </th>
                    <th v-if="hasTransactionVideo">
                      {{ t('transactions.end_time').toUpperCase() }}
                    </th>
                    <th v-if="hasTransactionVideo">
                      {{ t('payment_methods.price').toUpperCase() }}
                    </th>
                    <th v-if="!hasTransactionVideo">
                      {{ t('transactions.description').toUpperCase() }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in paymentData.transactions"
                    :key="item.id"
                  >
                    <td>
                      #{{ item.id }}
                      <v-chip
                        v-if="paymentData.status === 'R'"
                        small
                        :color="resolveClientAvatarVariant(item.refunded)"
                        :class="`${resolveClientAvatarVariant(item.refunded)}--text`"
                        class="v-chip-light-bg font-weight-semibold text-capitalize ml-2"
                      >
                        {{ item.refunded_str }}
                      </v-chip>
                    </td>
                    <td>
                      {{ item.transaction_date }}
                    </td>
                    <td v-if="hasTransactionVideo">
                      {{ item.video.video_date }}
                    </td>
                    <td v-if="hasTransactionVideo">
                      {{ item.video.field_name }}
                    </td>
                    <td v-if="hasTransactionVideo">
                      {{ item.video.start_time }}
                    </td>
                    <td v-if="hasTransactionVideo">
                      {{ item.video.end_time }}
                    </td>
                    <td v-if="hasTransactionVideo">
                      {{ formatCurrency(item.subtotal) }}
                    </td>
                    <td v-if="!hasTransactionVideo">
                      {{ item.description }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>

          <v-card-text class="py-7 px-8">
            <v-row justify="space-between">
              <v-col
                cols="12"
                md="3"
              >
                <span class="font-weight-semibold test-body-1">
                  {{ t('transactions.payment_agent') }}:
                </span>
                <!-- <span class="font-weight-light test-body-1">
                  {{ paymentData.user? paymentData.user : paymentData.user_app ? paymentData.user_app.fullname : '' }}
                </span> -->
                <span class="mb-1">
                  {{ paymentData.user_app.fullname }}
                </span>
                <span class="mb-1">
                  {{ paymentData.user_app.phone_number }}
                </span>
                <span class="mb-0">
                  {{ paymentData.user_app.email }}
                </span>
              </v-col>

              <v-col
                cols="12"
                md="3"
              >
                <div class="mb-2 mb-sm-0 d-flex">
                  <span v-if="paymentData.note">{{ `${t('transactions.note')}:  ${paymentData.note}` }}</span>
                </div>
                <div>
                  <table
                    v-if="paymentData.status !== 'D'"
                    class="w-100"
                  >
                    <tr>
                      <td class="pe-16 font-weight-light">
                        Subtotal:
                      </td>
                      <th
                        class="font-weight-light"
                        :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                      >
                        {{ paymentData.transactions.length ? getSubtotals(paymentData.transactions) : 0 }}
                      </th>
                    </tr>

                    <tr v-if="paymentData.transactions.length ? getDiscounts(paymentData.transactions) !== '($0.00)' : false">
                      <td class="pe-16 font-weight-light">
                        {{ t('video_details.discount') }}:
                      </td>
                      <th
                        class="font-weight-light"
                        :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                      >
                        {{ paymentData.transactions.length ? getDiscounts(paymentData.transactions) : 0 }}
                      </th>
                    </tr>
                    <tr>
                      <td class="pe-16 font-weight-light">
                        {{ t('video_details.tax') }}:
                      </td>
                      <th
                        class="font-weight-light"
                        :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                      >
                        {{ paymentData.transactions.length ? getTax(paymentData.transactions) : 0 }}
                      </th>
                    </tr>
                  </table>
                  <v-divider class="mt-4 mb-3"></v-divider>
                  <table class="w-full">
                    <tr>
                      <td class="pe-16 font-weight-light">
                        Total:
                      </td>
                      <th
                        class="font-weight-semibold"
                        :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                      >
                        {{ paymentData.amount ? formatCurrency(paymentData.amount) : 0 }}
                      </th>
                    </tr>
                    <tr
                      v-if="paymentData.status === 'R'"
                    >
                      <td class="pe-16 success--text font-weight-light">
                        {{ t('status.refunded') }}:
                      </td>
                      <th :class="$vuetify.rtl ? 'text-left success--text font-weight-bold' : 'text-right success--text font-weight-bold'">
                        {{ paymentData.transactions.length ? getRefunded(paymentData.transactions) : 0 }}
                      </th>
                    </tr>
                  </table>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col
        cols="12"
      >
        <v-card v-if="paymentData">
          <v-fab-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  dark
                  absolute
                  small
                  top
                  right
                  fab
                  class="mb-4 me-3"
                  v-on="on"
                  @click="goBack"
                >
                  <v-icon>
                    {{ icons.mdiTableArrowLeft }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t("tooltip.back_table") }}</span>
            </v-tooltip>
          </v-fab-transition>

          <v-card-text class="py-9 px-8">
            <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
              <div class="mb-8 mb-sm-0">
                <div class="d-flex align-center mb-6">
                  <v-img
                    :src="appLogo"
                    max-height="50px"
                    max-width="50px"
                    alt="logo"
                    contain
                    class="me-3 "
                  ></v-img>
                  <span
                    class="font-weight-bold text-xl"
                    style="color: white !important; font-family: Orbitron"
                  >
                    {{ appName }}
                  </span>
                </div>
                <span class="d-block"> {{ t('info.visit_us') }}
                  <a
                    style="color:#ff3232;"
                    href="https://fanaty.com/"
                  >https://fanaty.com/
                  </a>
                </span>
                <span class="d-block">{{ t('info.contact_us') }} <a
                  style="color:#ff3232;"
                  href="https://fanaty.com/"
                >support@fanaty.com</a></span>
              </div>
              <div>
                <p class="font-weight-medium text-xl text--primary mb-4 d-flex align-center">
                  {{ t('payment_methods.payment') }} #{{ paymentData.id }}
                  <v-chip
                    small
                    :color="resolveClientAvatarVariant(paymentData.status)"
                    :class="`${resolveClientAvatarVariant(paymentData.status)}--text`"
                    class="v-chip-light-bg font-weight-semibold text-capitalize ml-2"
                  >
                    {{ paymentData.status_str }}
                  </v-chip>
                </p>
                <p class="mb-2">
                  <span>{{ t('payment_methods.payment_date') }} </span>
                  <span class="font-weight-semibold">{{ paymentData.payment_date }}</span>
                </p>
              </div>
              <div class="mb-0 mb-sm-0 d-flex justify-lg-space-between">
                <v-btn
                  v-if="hasPermission(98)"
                  color="secondary"
                  block
                  @click="generateReport()"
                >
                  <v-icon
                    class="me-2"
                    left
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <span>{{ t('tooltip.print') }}</span>
                </v-btn>
              </div>
              <div
                class="mb-0 mb-sm-0"
              ></div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="py-9 px-8">
            <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
              <div
                v-if="paymentData.user_app && paymentData.user_app.fullname"
                class="mb-8 mb-sm-0"
              >
                <p class="font-weight-semibold payment-details-header">
                  {{ t('Invoice_to') }}:
                </p>
                <p class="mb-1">
                  {{ paymentData.user_app.fullname }}
                </p>
                <p class="mb-1">
                  {{ paymentData.user_app.phone_number }}
                </p>
                <p class="mb-0">
                  {{ paymentData.user_app.email }}
                </p>
              </div>
              <div
                v-else-if="paymentData.payment_method && paymentData.payment_method.name"
                class="mb-8 mb-sm-0"
              >
                <p class="font-weight-semibold payment-details-header">
                  {{ t('Invoice_to') }}:
                </p>
                <p class="mb-1">
                  {{ paymentData.payment_method.name }}
                </p>
                <p class="mb-1">
                  {{ paymentData.payment_method.email }}
                </p>
              </div>
              <div v-if="!paymentData.payment">
                <p class="font-weight-semibold payment-details-header">
                  {{ t('transactions.payment_method') }}:
                </p>
                <table>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.brand') }}
                    </td>
                    <td>
                      {{ paymentData.payment_method.brand }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.card_number') }}:
                    </td>
                    <td>
                      {{ paymentData.payment_method.number }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pe-6">
                      {{ t('payment_methods.zip_code') }}:
                    </td>
                    <td>
                      {{ paymentData.payment_method.billing_zip_code }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-card-text>

          <v-simple-table
            v-if="paymentData.status !== 'D'"
            class="purchased-items-table"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th>
                    {{ t('transactions.id').toUpperCase() }}
                  </th>
                  <th>
                    {{ t('transactions.transaction_date').toUpperCase() }}
                  </th>
                  <th v-if="hasTransactionVideo">
                    {{ t('transactions.video_info').toUpperCase() }}
                  </th>
                  <th v-if="hasTransactionVideo">
                    {{ t('transactions.field').toUpperCase() }}
                  </th>
                  <th v-if="hasTransactionVideo">
                    {{ t('transactions.start_time').toUpperCase() }}
                  </th>
                  <th v-if="hasTransactionVideo">
                    {{ t('transactions.end_time').toUpperCase() }}
                  </th>
                  <th v-if="hasTransactionVideo">
                    {{ t('payment_methods.price').toUpperCase() }}
                  </th>
                  <th v-if="!hasTransactionVideo">
                    {{ t('transactions.description').toUpperCase() }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in paymentData.transactions"
                  :key="item.id"
                >
                  <td>
                    #{{ item.id }}
                    <v-chip
                      v-if="paymentData.status === 'R'"
                      small
                      :color="resolveClientAvatarVariant(item.refunded)"
                      :class="`${resolveClientAvatarVariant(item.refunded)}--text`"
                      class="v-chip-light-bg font-weight-semibold text-capitalize ml-2"
                    >
                      {{ item.refunded_str }}
                    </v-chip>
                  </td>
                  <td>
                    {{ item.transaction_date }}
                  </td>
                  <td v-if="hasTransactionVideo">
                    {{ item.video.video_date }}
                  </td>
                  <td v-if="hasTransactionVideo">
                    {{ item.video.field_name }}
                  </td>
                  <td v-if="hasTransactionVideo">
                    {{ item.video.start_time }}
                  </td>
                  <td v-if="hasTransactionVideo">
                    {{ item.video.end_time }}
                  </td>
                  <td v-if="hasTransactionVideo">
                    {{ formatCurrency(item.subtotal) }}
                  </td>
                  <td v-if="!hasTransactionVideo">
                    {{ item.description }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-card-text class="py-9 px-8">
            <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
              <div class="mb-2 mb-sm-0">
              </div>
              <div>
                <table v-if="paymentData.status !== 'D'">
                  <tr>
                    <td class="pe-16">
                      Subtotal:
                    </td>
                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                      {{ paymentData.transactions.length ? getSubtotals(paymentData.transactions) : 0 }}
                    </th>
                  </tr>
                  <tr
                    v-if="paymentData.transactions.length ? getDiscounts(paymentData.transactions) !== '($0.00)' : false"
                  >
                    <td class="pe-16 success--text font-weight-bold">
                      {{ t('video_details.discount') }}:
                    </td>
                    <th :class="$vuetify.rtl ? 'text-left success--text font-weight-bold' : 'text-right success--text font-weight-bold'">
                      {{ paymentData.transactions.length ? getDiscounts(paymentData.transactions) : 0 }}
                    </th>
                  </tr>
                  <tr>
                    <td class="pe-16">
                      {{ t('video_details.tax') }}
                    </td>
                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                      {{ paymentData.transactions.length ? getTax(paymentData.transactions) : 0 }}
                    </th>
                  </tr>
                </table>
                <v-divider class="mt-4 mb-3"></v-divider>
                <table class="w-full">
                  <tr>
                    <td class="pe-16">
                      Total:
                    </td>
                    <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                      {{ paymentData.amount ? formatCurrency(paymentData.amount) : 0 }}
                    </th>
                  </tr>
                  <tr
                    v-if="paymentData.status === 'R'"
                  >
                    <td class="pe-16 success--text font-weight-bold">
                      {{ t('status.refunded') }}:
                    </td>
                    <th :class="$vuetify.rtl ? 'text-left success--text font-weight-bold' : 'text-right success--text font-weight-bold'">
                      {{ paymentData.transactions.length ? getRefunded(paymentData.transactions) : 0 }}
                    </th>
                  </tr>
                </table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>

    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      :filename="paymentData? `Receipt #${paymentData.id} ${paymentData.payment_date}` : 'Receipt'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <payment-history-report
          :data-params="computedPaymentData"
          :has-transaction-video="hasTransactionVideo"
        ></payment-history-report>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import { getPaymentUserApp } from '@api'
import { mdiSendOutline, mdiCurrencyUsd, mdiTableArrowLeft, mdiPrinter } from '@mdi/js'
import themeConfig from '@themeConfig'
import { useRouter, formatCurrency } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import { useUtils } from '@core/libs/i18n'
import { info, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'
import useSelectOptions from '@/@core/utils/useSelectOptions'

import VueHtml2pdf from 'vue-html2pdf'
import PaymentHistoryReport from '@/templates/reports/PaymentHistoryReport.vue'

// composition function
import usePaymentList from '../payment-list/usePaymentList'

export default {
  components: {
    PaymentHistoryReport,
    VueHtml2pdf,
  },
  setup() {
    const { route, router } = useRouter()
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const {
      configOrganization,
      configFacility,
    } = useSelectOptions()

    const dataId = ref(null)
    const paymentData = ref(null)
    const computedFacility = computed(() => {
      if (paymentData.value.transactions.length) {
        return {
          id: paymentData.value.transactions[0].facility_id,
          logo: paymentData.value.transactions[0].facility_logo,
          name: paymentData.value.transactions[0].facility_name,
          address: paymentData.value.transactions[0].facility_short_address,
          phone: paymentData.value.transactions[0].facility_phone_number,
        }
      }

      return {
        id: null,
        logo: null,
        facility_name: null,
        facility_address: null,
        phone: null,
      }
    })
    const initData = ref({
      id: null,
      amount: null,
      description: null,
      payment_date: null,
      status: null,
      user: {
        username: null,
        fullname: null,
      },
      user_app: {
        username: null,
        fullname: null,
      },
      group: {
        group_name: null,
        logo: null,
        email: null,
        phone: null,
      },
      payment_method: {
        id: null,
        owner_name: null,
        number: null,
        brand: null,
        billing_address: null,
        billing_email: null,
        billing_zip_code: null,
        group_id: null,
        billing_phone: null,
      },
      transactions: [],
    })
    const computedPaymentData = computed(() => {
      if (paymentData.value) return paymentData.value

      return initData.value
    })
    const videoData = ref(null)
    const transactionData = ref(null)
    const hasTransactionVideo = ref(true)
    const pageData = ref()
    const filterData = ref()
    const html2Pdf = ref(null)
    const dateNow = ref(new Date().toISOString().substring(0, 10))

    const { resolveClientAvatarVariant } = usePaymentList()

    const getSubtotals = trans => {
      const subtotal = trans.reduce((acc, cur) => acc + cur.subtotal, 0)

      return formatCurrency(subtotal)
    }

    const getDiscounts = trans => {
      const discount = trans.reduce((acc, cur) => acc + cur.discount, 0)

      return discount !== 0 ? `(${formatCurrency(discount)})` : '($0.00)'
    }

    const getComission = trans => {
      const comission = trans.reduce((acc, cur) => acc + cur.comission, 0)

      return comission !== 0 ? `(${formatCurrency(comission)})` : '($0.00)'
    }

    const getTax = trans => {
      const tax = trans.reduce((acc, cur) => acc + cur.tax, 0)

      return tax !== 0 ? `${formatCurrency(tax)}` : '$0.00'
    }

    const getRefunded = trans => {
      const refunded = trans.filter(e => e.refunded === 'Y').reduce((acc, cur) => acc + cur.subtotal, 0)

      return refunded !== 0 ? `${formatCurrency(refunded)}` : '($0.00)'
    }

    const goBack = () => {
      if (videoData.value) {
        router.push({
          name: 'views-video-preview',
          params: {
            id: videoData.value.id,
            dataParams: videoData.value,
            dataTransaction: transactionData.value,
            option: 2,
            pageParams: pageData.value,
            filterParams: filterData.value,
          },
        })
      } else {
        router.replace({
          name: 'views-fanaty-payment-list',
          params: {
            pageParams: pageData.value,
            filterParams: filterData.value,
          },
        })
      }
    }

    const generateReport = () => {
      html2Pdf.value.generatePdf()
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      dataId.value = route.value.params.id
      videoData.value = route.value.params.dataVideo
      transactionData.value = route.value.params.dataTransaction
      pageData.value = route.value.params.pageParams
      filterData.value = route.value.params.filterParams

      if (dataId.value) {
        const resp = await getPaymentUserApp(dataId.value)
        if (resp.ok) {
          paymentData.value = resp.data
        } else goBack()
      } else goBack()

      if (!paymentData.value) {
        goBack()
      } else if (paymentData.value.transactions.length) {
        hasTransactionVideo.value = !paymentData.value.transactions.some(e => e.video === null)
        if (!hasTransactionVideo.value) {
          const mapData = paymentData.value.transactions
          paymentData.value.transactions = mapData.map(e => {
            return {
              ...e,
              description: paymentData.value.description,
            }
          })
        }
      }
    })

    return {
      // data
      paymentData,
      initData,
      computedPaymentData,
      videoData,
      hasTransactionVideo,
      pageData,
      filterData,
      dateNow,
      html2Pdf,
      computedFacility,

      // methods
      formatCurrency,
      getSubtotals,
      getDiscounts,
      getComission,
      getTax,
      getRefunded,
      goBack,
      resolveClientAvatarVariant,
      generateReport,
      beforeDownload,
      hasDownloaded,
      hasPermission,
      avatarText,

      // i18n
      t,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiTableArrowLeft,
        mdiPrinter,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
